import { setCookie } from './setCookie';
import { getCookie } from './getCookie';
import { deleteCookie } from './deleteCookie';
import { fullWidthVideoPlayerLink } from '../video/fullWidthVideoPlayerLink';
import { videoPopupPlayerLink } from '../video/videoPopupPlayerLink';
import { delayHomeBannerVideoLink } from '../video/delayHomeBannerVideoLink';

export function cookieConsent() {
  let cookiePreferences = getCookie('popupCookieAllowed').split(',');
  if (cookiePreferences[0] == 'necessary%2Cperformance%2Cfunctional%2Ctargetting') {
    cookiePreferences = cookiePreferences[0].split('%2C');
  }
  // On-Load Cookie Popup and Fixed Icon
  if( getCookie('popupCookie') != 'submited'){
    if ($('#cookie-consent-section').length >= 1) {
      setTimeout(function() {
        $('#cookie-consent-section').slideDown('slow');
      }, 500);
    }
  } else {
    if (cookiePreferences.includes('functional')) {
      delayHomeBannerVideoLink();
      fullWidthVideoPlayerLink();
      videoPopupPlayerLink();
      $('.video-iframe-thumbnail.video-url').removeClass('disable-popup');
    } else {
      $('.video-iframe-thumbnail.video-url').addClass('disable-popup');
    }

    if ($('#fixed-cookie-logo').length >= 1) {
      setTimeout(function() {
        $('#fixed-cookie-logo').slideDown('slow');
      }, 500);
    }
  }
  // On-Load Cookie Popup and Fixed Icon

  // Accept All Button Click
  $('#accept-all-button').on('click', function () {
    $('#cookie-consent-section').slideUp('slow', function() {
      $('#fixed-cookie-logo').slideDown('slow');
    });
    setCookie('popupCookie', 'submited', 100);
    setCookie('popupCookieAllowed', ['necessary', 'performance', 'functional', 'targetting'], 100);
    delayHomeBannerVideoLink();
    fullWidthVideoPlayerLink();
    videoPopupPlayerLink();
    $('.video-iframe-thumbnail.video-url').removeClass('disable-popup');
  });
  // Accept All Button Click

  // Accept Necessary Button Click
  $('#accept-necessary-button').on('click', function () {
    $('#cookie-consent-section').slideUp('slow', function() {
      $('#fixed-cookie-logo').slideDown('slow');
    });
    setCookie('popupCookie', 'submited', 100);
    setCookie('popupCookieAllowed', ['necessary'], 100);
    deleteGoogleCookies();
    $('.video-iframe-thumbnail.video-url').addClass('disable-popup');
  });
  // Accept Necessary Button Click

  // Accept Customize Accept Button Click
  $('#confirm-preferences').on('click', function () {
    setCookie('popupCookie', 'submited', 100);
    let cookieOptions = [];
    if ($('#necessary-cookies-toggle').is(':checked')) {
      cookieOptions.push('necessary');
    }
    if ($('#performance-cookies-toggle').is(':checked')) {
      cookieOptions.push('performance');
    } else {
      deleteGoogleCookies();
    }
    if ($('#functional-cookies-toggle').is(':checked')) {
      cookieOptions.push('functional');
      delayHomeBannerVideoLink();
      fullWidthVideoPlayerLink();
      videoPopupPlayerLink();
      $('.video-iframe-thumbnail.video-url').removeClass('disable-popup');
    } else {
      $('.video-iframe-thumbnail.video-url').addClass('disable-popup');
    }
    if ($('#targetting-cookies-toggle').is(':checked')) {
      cookieOptions.push('targetting');
    }

    $('#cookiePopupModal').modal('hide');

    setCookie('popupCookieAllowed', cookieOptions, 100);
    $('#cookie-consent-section').slideUp('slow', function() {
      $('#fixed-cookie-logo').slideDown('slow');
    });
  });
  // Accept Customize Accept Button Click

  // Cookie Fixed Icon Click
  $('#fixed-cookie-logo').on('click', function () {
    let cookiePreferences = getCookie('popupCookieAllowed').split(',');
    if (cookiePreferences[0] == 'necessary%2Cperformance%2Cfunctional%2Ctargetting') {
      cookiePreferences = cookiePreferences[0].split('%2C');
    }

    if (cookiePreferences.includes('performance')) {
      $('#performance-cookies-toggle').prop('checked', true);
    } else {
      $('#performance-cookies-toggle').prop('checked', false);
    }

    if (cookiePreferences.includes('functional')) {
      $('#functional-cookies-toggle').prop('checked', true);
    } else {
      $('#functional-cookies-toggle').prop('checked', false);
    }

    if (cookiePreferences.includes('targetting')) {
      $('#targetting-cookies-toggle').prop('checked', true);
    } else {
      $('#targetting-cookies-toggle').prop('checked', false);
    }

    $('#fixed-cookie-logo').slideUp('slow', function() {
      $('#cookie-consent-section').slideDown('slow');
    });
  });
  // Cookie Fixed Icon Click

  // Delete Google Analytic Cookies
  function deleteGoogleCookies() {
    deleteCookie('_ga');
    deleteCookie('_ga_EXFNGAKKTZ');
  }
  // Delete Google Analytic Cookies
}