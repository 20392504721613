/*
  Creates the functionality of of news items
*/

// Load More Removed as CR

export function loadMore() {
  // $('.events-outer-wrp .events-item-wrp').slice(0, 5).show();
  // if ($('.events-outer-wrp .events-item-wrp:hidden').length != 0 ) {
  //   $('#loadMoreDesktop').show();
  // } else {
  //   $('#loadMoreDesktop').hide();
  // }
  // $('#loadMoreDesktop').on('click', function (e) {
  //   e.preventDefault();
  //   $('.events-outer-wrp .events-item-wrp:hidden').slice(0, 5).slideDown();
  //   if ($('.events-outer-wrp .events-item-wrp:hidden').length == 0) {
  //     $('#loadMoreDesktop').text('No More to view').fadeOut('slow');
  //     $('#loadMoreDesktop').addClass('load-more-end');
  //   }
  // });
}