/*
  Clear Searchbar
*/

export function clearSearch() {
  if($('body').is('.template-events-listing')){
    if ($('.events-listing-section form input.deletable').val().length != 0) {
      $('.events-listing-section form .clear-search').css('display', 'inline-block');
    }

    $('.events-listing-section form input.deletable').on('keyup change', function() {
      if ($('.events-listing-section form input.deletable').val().length == 0) {
        $('.events-listing-section form .clear-search').css('display', 'none');
      } else {
        $('.events-listing-section form .clear-search').css('display', 'inline-block');
      }
    });

    $('.events-listing-section form .clear-search').click(function() {
      $('.events-listing-section form input.deletable').val('');
      $('.events-listing-section form .clear-search').css('display', 'none');
      $('.events-listing-section form input.deletable').focus();
    })
  }

}