/*
  check and add classes to all sections
*/

export function checkSectionsForGaps() {
  $('.three-column-section-new').each(function () {
    if($(this).next().hasClass('three-column-section-new bg-enabled')) {
      $(this).addClass('bg-next');
    }
  });

  $('.three-column-section-new.bg-enabled.color-1').each(function () {
    if($(this).next().hasClass('three-column-section-new bg-enabled color-1')) {
      $(this).addClass('same-bg-next');
    }
  });

  $('.three-column-section-new.bg-enabled.color-2').each(function () {
    if($(this).next().hasClass('three-column-section-new bg-enabled color-2')) {
      $(this).addClass('same-bg-next');
    }
  });

  $('.three-column-section-new.bg-enabled.color-3').each(function () {
    if($(this).next().hasClass('three-column-section-new bg-enabled color-3')) {
      $(this).addClass('same-bg-next');
    }
  });

  $('.three-column-section-new.bg-enabled.color-4').each(function () {
    if($(this).next().hasClass('three-column-section-new bg-enabled color-5')) {
      $(this).addClass('same-bg-next');
    }
  });

  $('.three-column-section-new.bg-enabled.color-6').each(function () {
    if($(this).next().hasClass('three-column-section-new bg-enabled color-6')) {
      $(this).addClass('same-bg-next');
    }
  });
}
