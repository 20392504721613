/*
  Add functionality to "Team Members Popup"
*/

export function teamMember() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.team-section .team-row').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    watchCSS: true,
    cellAlign: cellAlignValue,
  });

  $('.fcp-section.team-section .team-wrp .expand-wrp').click(function(){
    if ($(this).parent().hasClass('expanded')) {
      $(this).parent().toggleClass('expanded');
    } else {
      $('.fcp-section.team-section .team-wrp .team-item-wrp').each(function() {
        $(this).children('.item-inner-wrp').removeClass('expanded');
      });

      $(this).parent().toggleClass('expanded');
    }
  });
}