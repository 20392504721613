/*
  Add functionality to "Add Buttons" in WYSIWYG
*/

export function editorContentAdditions() {
  //wrap all buttons with a wrapper to handle responsive - This works with "Add Buttons" feature in the wp editor
  var target = '.btn',
      invert = ':not(' + target + ')',
      wrap = '<div class="button-wrapper">',
      breakpoints = $('.editor-content-wrp > *'+invert);

  $('.editor-content-wrp').each(function( ) {
    $(this).find('.btn').unwrap();
  });

  breakpoints.each(function(){
    $(this).nextUntil(invert).wrapAll(wrap);
  });

  breakpoints.first().prevUntil(invert).wrapAll(wrap);
  //wrap all buttons with a wrapper to handle responsive - This works with "Add Buttons" feature in the wp editor
}