/*
  Makes the header nagivation sticky
*/

import { getCurrentScroll } from './getCurrentScroll';

export function stickyMenu() {
  stickyMenuClassAdd();
  $(window).scroll(function () {
    stickyMenuClassAdd();
    // set header height to sticky overlay
    $('.sticky-overlay').outerHeight($('.header').outerHeight());
  });
}

function stickyMenuClassAdd() {
  var shrinkHeader = 5;
  var scroll = getCurrentScroll();
    if (scroll >= shrinkHeader) {
      $('body').addClass('sticky-header');
    } else {
      $('body').removeClass('sticky-header');
    }
}