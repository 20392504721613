/*
  Creates a new cookie with the given inputs where,
  'cname' is the cookie name,
  'cvalue' is the cookie value,
  'exdays' is how much days the cookie should be working
*/

export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}