/*
  Plays popup video which has a video file
*/

export function videoPopupPlayerFile() {
  var videoSrc;
  var videoControls;
  $('.video-iframe-thumbnail.video-file').click(function () {
    videoSrc = $(this).data('src');
    videoControls = $(this).data('controls');
    console.log(videoSrc);
  });

  $('#myModal-video-file.video-file').on('shown.bs.modal', function () {
    console.log(videoSrc);
    $('#myModal-video-file #video').attr(
      'src',
      videoSrc + '?autoplay=1&mute=1&amp&modestbranding=1&amp&'+ videoControls+'&showinfo=0;'
    );
  });

  $('#myModal-video-file').on('hide.bs.modal', function () {
    $('#myModal-video-file #video').attr('src', '');
  });
}