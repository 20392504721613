/*
  Gallery section slider
*/

export function gallerySectionSlider() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.gallery-section.v1 .gallery-wrapper').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    adaptiveHeight: true,
    cellAlign: cellAlignValue,
  });

  $('.gallery-section.v1').each(function () {
    $(this).find('.flickity-button').appendTo($(this).find('.intro-inner'));
  });
}