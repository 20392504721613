/*
  Cards section grid slider new
*/

export function cardSectionSliderNew() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.cards-section-new.slider .cards-wrp').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: cellAlignValue,
  });

  $('.cards-section-new.slider').each(function () {
    $(this).find('.flickity-button').appendTo($(this).find('.intro-content-var2 .intro-inner'));
  });
}