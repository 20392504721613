/*
  Cards section grid slider
*/

export function cardSectionGridSlider() {
  $('.cards-section.v1 .cards-wrp').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    watchCSS: true,
  });
}