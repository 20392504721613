/*
  Jquery Viewer Galler Popup
*/

export function jqueryViewer(ulId) {
  var $galleryImages = $(ulId);

  $galleryImages.each(function() {
    $galleryImages.viewer({
      tooltip: 0,
      inline: false,
      toolbar: {
        zoomIn: {
          show: 1,
          size: 'large',
        },
        zoomOut: {
          show: 1,
          size: 'large',
        },
        oneToOne: 0,
        reset: 0,
        prev: {
          show: 1,
          size: 'large',
        },
        play: {
          show: 0,
          size: 'large',
        },
        next: {
          show: 1,
          size: 'large',
        },
        rotateLeft: 0,
        rotateRight: 0,
        flipHorizontal: 0,
        flipVertical: 0,
      },
      title: 0,
      viewed: function () {
        $galleryImages.viewer('zoomTo', 1);
      },
    });
  });
}