/*
  Cards section slider new
*/

export function cardSectionGridSliderNew() {
  $('.cards-section-new.grid .cards-wrp').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    watchCSS: true,
  });

  $('.cards-section-new.grid').each(function () {
    $(this).find('.flickity-button').appendTo($(this).find('.flickity-button-wrp'));
  });
}