/*
  product detail page product image slider
*/

export function productImageSlider() {
  $(document).ready(function ($) {
    // Initialize Flickity slider on the product gallery
    $('.woocommerce-product-gallery__wrapper').flickity({
      cellAlign: 'center',
      contain: true,
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true,
      autoPlay: false,
      imagesLoaded: true,
    });

    $('.product-thumbnails').flickity({
      asNavFor: '.woocommerce-product-gallery__wrapper',
      contain: true,
      pageDots: false,
      prevNextButtons: false,
      cellAlign: 'left',
      wrapAround: true,
      autoPlay: false,
      imagesLoaded: true,
    });
  });
}
